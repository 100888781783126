<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}" >
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Page <span>Rename</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="form_grp">
                        <Form @submit="handleSubmitForm" v-slot="{ errors }" ref="rename-page-form">
                            <div class="group_item">
                                <label class="input_label">Page Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.title }">
                                    <Field autocomplete="off" type="text" name="title" v-model="form.title" placeholder="ex: Example Page" rules="required" />
                                </div>
                                <ErrorMessage name="title" class="text-danger" />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Rename Page',

        data () {
            return {
                form: {
                    title: '',
                    id: '',
                }
            }
        },

        props: {
            modelValue: Boolean,
            page: Object,
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.form = {
                        title: vm.page.title,
                        id: vm.page.id,
                    }
                }
            }
        },

        computed: mapState({
            loader: state => state.pageModule.pageUserLoader,
        }),

        methods: {
            ...mapActions({
                renamePage: 'pageModule/renamePage'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmitForm () {
                const vm = this;

                const form = vm.$refs['rename-page-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.handleRenamePage(form);
                    }
                });
            },

            handleRenamePage (form) {
                const vm = this;

                vm.form.setFieldError = form.setFieldError;

                vm.renamePage(vm.form).then((result) => {
                    if (result) {
                        if (vm.$parent.$parent.refreshPage) {
                            vm.$parent.$parent.refreshPage();
                        } else {
                            vm.$parent.refreshPage();
                        }

                        vm.closeModal();
                    }
                });
            }
        }
    }
</script>
